<template>
    <div>
        <Panel module="Comunicação" layout="large" :showFilter="false" :showSearch="false" :showButtons="false">
            <div slot="content-main">
                <br />
                <div v-show="show">
                    <b-row>
                        <b-col xs="12" sm="12" md="12" lg="4" xl="4">
                            <div class="div-channel">
                                <RadioGroup field="channel" :options="options" v-model="channel" />
                            </div>
                        </b-col>
                        <b-col xs="12" sm="12" md="12" lg="4" xl="2">
                            <div class="div-filter-user">
                                <ResponsibleUser v-if="show" :responsibleUser="userLogged.user" :channel="channel"
                                    :multiSelected="true" urlGet="/api/v1/adm/user/select-all" v-model="usersFilter" />
                            </div>
                        </b-col>
                        <b-col xs="12" sm="12" md="12" lg="6" xl="5">
                            <div class="div-btn-group">
                                <ButtonGroup :options="optionsButtonGroup" v-model="optionSelected" />
                            </div>
                        </b-col>
                    </b-row>
                    <div class="div-chat">
                        <Chat v-if="channel == 1 && hasRuleCrm" :channel="1" />
                        <Chat v-if="channel == 2 && hasRuleSupport" :channel="2" />
                        <Chat v-if="channel == 3 && hasRuleFinance" :channel="3" />
                    </div>
                </div>
                <div v-show="loadingLoading">
                    <Loading type="line" :center="false" />
                </div>
            </div>
        </Panel>
        <Modal title="Fila de Lead" :width="1000" :height="750" v-show="showModal('leadQueue')" :onHideModal="onHideModal">
            <LeadQueueList v-if="show" />
        </Modal>
        <Modal title="Lembretes" :width="1000" :height="750" v-show="showModal('schedule')" :onHideModal="onHideModal">
            <ScheduleList v-if="show" :channel="channel" />
        </Modal>
        <Modal title="Eventos WhatsApp" :width="1000" :height="750" v-show="showModal('logGupShup')"
            :onHideModal="onHideModal">
            <LogGupShupList :channel="channel" />
        </Modal>
    </div>
</template>

<script>

import ButtonGroup from "@nixweb/nixloc-ui/src/component/forms/ButtonGroup";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";
import Panel from "@nixweb/nixloc-ui/src/component/layout/Panel";
import RadioGroup from "@nixweb/nixloc-ui/src/component/forms/RadioGroup";
import Select from "@nixweb/nixloc-ui/src/component/forms/Select";

import ResponsibleUser from "../../../components/modules/chat/responsible-user/ResponsibleUser.vue";

import Chat from '../../../components/modules/chat/whatsapp/Chat.vue'
import LeadQueueList from '../../../components/modules/crm/lead/LeadQueueList.vue'
import LogGupShupList from '../../../components/modules/chat/log-gupshup/LogGupShupList.vue'
import ScheduleList from '../../../components/modules/chat/schedule/ScheduleList.vue'

import { mapMutations, mapGetters, mapState } from "vuex";

export default {
    name: "ChatView",
    components: {
        ButtonGroup,
        Modal,
        Loading,
        Panel,
        RadioGroup,
        Select,
        ResponsibleUser,
        Chat,
        LeadQueueList,
        ScheduleList, LogGupShupList,
    },
    data() {
        return {
            channel: 0,
            loadingChat: false,
            loadingLoading: false,
            optionSelected: "",
            usersFilter: [],
        }
    },
    mounted() {
        this.channel = this.hasRuleCrm ? 1 : (this.hasRuleSupport ? 2 : (this.hasRuleFinance ? 3 : 0));
    },
    computed: {
        ...mapGetters("user", ["hasRule", "hasPermission"]),
        ...mapState("user", ["userLogged"]),
        ...mapGetters("generic", ["event", "showModal"]),
        ...mapState("chat", ["leadQueue", "schedule"]),
        options() {
            var options = [{ text: 'CRM', value: 1 }, { text: 'Financeiro', value: 3 }, { text: 'Suporte', value: 2 },]
            var optionsConditional = [];

            options.forEach(option => {
                if (option.text === "CRM" && this.hasRuleCrm) optionsConditional.push(option);
                if (option.text === "Financeiro" && this.hasRuleFinance) optionsConditional.push(option);
                if (option.text === "Suporte" && this.hasRuleSupport) optionsConditional.push(option);
            });
            return optionsConditional;
        },
        optionsButtonGroup() {
            var options = [
                { title: 'Leads de Entrada', rule: "CRM", badge: this.leadQueue.length, value: "leadQueue" },
                { title: 'Lembretes', rule: "all", badge: this.schedule.length, value: "schedule" },
                { title: 'Eventos WhatsApp', rule: "all", value: "logGupShup" }
            ];
            var optionsConditional = [];

            options.forEach(option => {
                if (option.rule === "CRM" && this.channel === 1) optionsConditional.push(option);
                if (option.rule === "all") optionsConditional.push(option);
            });
            return optionsConditional;
        },
        hasRuleCrm() {
            var crm = this.hasPermission("CRM");
            if (crm) {
                return crm.includes("chat");
            }
            return false;
        },
        hasRuleFinance() {
            var crm = this.hasPermission("Finance");
            if (crm) {
                return crm.includes("chat");
            }
            return false;
        },
        hasRuleSupport() {
            var support = this.hasPermission("Support");
            if (support) {
                return support.includes("chat");
            }
            return false;
        },
        show() {
            return !this.loadingLoading && this.channel != 0;
        }
    },
    methods: {
        ...mapMutations("chat", ["cleanRooms", "updateUsersFilter", "cleanQueue", "cleanSchedule"]),
        ...mapMutations("generic", ["addEvent", "openModal"]),
        onHideModal() {
            this.optionSelected = "";
        },
    },
    watch: {
        channel: {
            handler() {
                this.loadingChat = true;
                this.loadingLoading = true;
                this.cleanRooms();
                this.cleanQueue();
                this.cleanSchedule();

                let self = this;
                setTimeout(function () {
                    self.loadingChat = false;
                }, 500);
                setTimeout(function () {
                    self.loadingLoading = false;
                }, 600);
            },
            deep: true,
        },
        optionSelected: {
            handler(value) {
                if (value === "leadQueue") {
                    this.openModal("leadQueue");
                    this.addEvent({ name: "getLeadQueue" });
                }
                if (value === "schedule") {
                    this.openModal("schedule");
                    this.addEvent({ name: "getSchedule" });
                }
                if (value === "logGupShup") {
                    this.openModal("logGupShup");
                    this.addEvent({ name: "getLogGupShup" });
                }
            },
            deep: true,
        },
        event: {
            handler(event) {
                if (event.name == "conversationStarted") this.onHideModal();
            },
            deep: true,
        },
        usersFilter: {
            handler(usersFilter) {
                this.updateUsersFilter(usersFilter);
            },
            deep: true,
        },
    },
}
</script>
<style scoped>
.div-filter-user {
    margin-top: 5px;
}

.div-btn-group {
    margin-top: 3px;
}

.div-chat {
    margin-top: 10px;
}
</style>
<template>
    <div>
        <vue-advanced-chat height="calc(100vh - 220px)" :show-search="true" :current-user-id="'user-internal'"
            :rooms="JSON.stringify(rooms)" :rooms-loaded="true" :messages="JSON.stringify(messagesFilter)"
            :messages-loaded="messagesLoaded" @send-message="sendMessage($event.detail[0])"
            show-new-messages-divider="false" :text-messages="JSON.stringify(textMessages)"
            @fetch-messages="fetchMessages($event.detail[0])" :menu-actions="JSON.stringify(roomActions)"
            :styles="JSON.stringify(style.light)" @menu-action-handler="menuActionHandle" @open-file="openFile"
            load-first-room="false" @add-room="openRoom">
            <div slot="room-header-avatar"></div>
            <div slot="room-header-info">
                <div v-show="loadingHeader">
                    <vue-loading type="bubbles" color="#EA7F29" :size="{ width: '50px', height: '50px' }"></vue-loading>
                </div>
                <div v-show="!loadingHeader && roomId">
                    <div class="side-by-side">
                        <div class="side-by-side">
                            {{ roomNameFull }}

                        </div>
                        <!--<div class="side-by-side link" @click="openModal('editRoom')">
                            {{ roomNameFull }}
                        </div> -->
                        <div class="side-by-side">
                            <div class="badge-chat customer" v-if="!isLead">cliente</div>
                            <div class="badge-chat lead" v-else>lead</div>
                        </div>
                        <div class="side-by-side div-user">
                            <div>
                                <ResponsibleUser :channel="channel" :multiSelected="false"
                                    :responsibleUser="responsibleUser" v-model="responsibleUser" :genericId="roomId"
                                    urlGet="/api/v1/adm/user/select-all"
                                    urlUpdate="/api/v1/chat/conversation/responsible-user" />
                            </div>
                        </div>
                        <div>
                            <i class="icon-whats fa-brands fa-whatsapp"></i>
                            {{ senderPhone }}
                            <span class="div-open-template" v-if="isNeedTemplate" @click="openTemplate">
                                - <i class="warning fa-sharp fa-solid fa-triangle-exclamation"></i> Template necessário
                            </span>
                        </div>
                    </div>
                    <div class="side-by-side text-center">
                        <div class="side-by-side div-schedule-job" @click="openModal('toSchedule')">
                            <div>
                                <span>
                                    <i class="fa-light fa-timer"></i>
                                    Lembrar
                                </span>
                            </div>
                        </div>
                        <div class="side-by-side div-schedule-job" @click="createJob()">
                            <div>
                                <span>
                                    <i class="fa-light fa-calendar"></i>
                                    Tarefa
                                </span>
                            </div>
                        </div>
                        <div class="side-by-side" v-if="cards.length > 0">
                            <div class="div-card">
                                <span>
                                    <i class="fa-solid fa-address-card"></i>
                                    {{ cards.length }}
                                </span>
                            </div>
                        </div>
                        <!-- <div class="side-by-side div-schedule-job" @click="createJob()">
                            <div>
                                <span>
                                    <i class="fa-light fa-note"></i>
                                    Nota
                                </span>
                            </div>
                        </div> -->
                        <div class="divider">
                            -
                        </div>
                    </div>
                    <div v-if="loadingSendMessage">
                        <Loading type="line" />
                    </div>
                </div>
            </div>
            <div slot="spinner-icon-infinite-messages">
                <div class="text-center">
                    <vue-loading type="bubbles" color="#EA7F29" :size="{ width: '50px', height: '50px' }"></vue-loading>
                </div>
            </div>
            <div slot="spinner-icon-messages">
                <div v-if="messages.length == 0">
                    <span class="not-message">
                        Nenhuma mensagem
                    </span>
                </div>
                <div v-else class="text-center">
                    <vue-loading type="bubbles" color="#EA7F29" :size="{ width: '60px', height: '60px' }"></vue-loading>
                </div>
            </div>
            <div slot="send-icon">
                <div class="div-btn-send">
                    <Button _key="btnSend" type="primary" classIcon="fa-solid fa-paper-plane-top" size="small" />
                </div>
            </div>
            <div slot="toggle-icon"></div>
        </vue-advanced-chat>

        <Modal title="Conversa" :width="800" v-if="showModal('openRoom')">
            <b-tabs>
                <b-tab title="Iniciar Conversa">
                    <ConversationCreate :channel="channel" />
                </b-tab>
                <b-tab title="Nova Conversa">
                    <NewConversationCreate :channel="channel" />
                </b-tab>
            </b-tabs>
        </Modal>

        <Modal title="Conversa" :width="800" v-if="showModal('editRoom')">
            <ConversationEdit :conversationEdit="conversationEdit" />
        </Modal>

        <Modal title="Template" :width="900" v-if="showModal('template')">
            <Template :templates="templates" :sendTemplate="sendTemplate" />
        </Modal>

        <Modal title="Encerrar Conversa" :width="600" v-if="showModal('closeRoom')">
            <div class="text-center">
                <Button _key="btnCloseRoom" type="danger" title="Encerrar" classIcon="fa-solid fa-right-from-bracket"
                    size="small" :clicked="handleCloseRoom" />
            </div>
        </Modal>

        <Modal title="Tarefa" :width="900" :height="750" v-show="showModal('job')">
            <JobCreateUpdate />
        </Modal>

        <Modal title="Mesclar" :width="650" :height="750" v-show="showModal('merge')">
            <Merge :contactId="contact.id" />
        </Modal>

        <Modal title="Lembrar" :width="600" :height="750" v-show="showModal('toSchedule')">
            <ScheduleCreate :conversationId="roomId" />
        </Modal>

        <Modal title="Cartão" :width="600" :height="750" v-show="showModal('card')">
            <CardCreateFromChat :customerSupplierId="conversationEdit.customerSupplier.id" />
        </Modal>
    </div>
</template>

<script>

import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";

import ConversationCreate from '../conversation/ConversationCreate.vue'
import ConversationEdit from '../conversation/ConversationEdit.vue'
import JobCreateUpdate from '../../shared/job/JobCreateUpdate.vue'
import Merge from '../merge/Merge.vue'
import NewConversationCreate from "../conversation/NewConversationCreate.vue";
import ResponsibleUser from "../../../modules/chat/responsible-user/ResponsibleUser.vue";
import ScheduleCreate from '../schedule/ScheduleCreate.vue'
import Template from '../template/Template.vue'
import CardCreateFromChat from '../../planner/card/CardCreateFromChat.vue'

import { register } from 'vue-advanced-chat'
import { style, language } from './config'

import { mapState, mapMutations, mapActions, mapGetters } from "vuex";

register();

export default {
    name: "Chat",
    props: {
        channel: Number
    },
    components: {
        Button,
        Modal,
        Loading,
        ConversationCreate,
        JobCreateUpdate,
        Merge,
        NewConversationCreate,
        ScheduleCreate,
        ResponsibleUser,
        Template, ConversationEdit, CardCreateFromChat
    },
    data() {
        return {
            roomId: "",
            isLead: false,
            senderPhone: "",
            senderEmail: "",
            roomNameFull: "",
            conversationEdit: { customerSupplier: { id: "" } },
            responsibleUser: {},
            cards: [],
            contact: {},
            urlGet: "/api/v1/chat/conversation/get-all",
            urlGetTemplate: "/api/v1/gupshup/get-template",
            urlClose: "/api/v1/chat/conversation/close",
            urlFile: "/api/v1/adm/file-upload/upload",
            urlCreateMessage: "/api/v1/chat/message/create",
            urlCreateMessageTemplate: "/api/v1/chat/message/create-template",
            urlGetMessage: "/api/v1/chat/message/get-all",
            urlMarkAsRead: "/api/v1/chat/conversation/mark-as-read",
            urlLastMessage: "/api/v1/chat/message/get-last-message-by-conversation-id",
            style: style,
            baseParams: {
                search: undefined,
                filter: "contains",
                currentPage: 1,
                totalPerPage: 30,
            },
            totalRecords: 0,
            textMessages: language,
            messages: [],
            messagesLoaded: false,
            loadingSendMessage: false,
            loadingChat: true,
            templates: [],
            loadingHeader: false,
            roomActions: [
                { name: 'createCard', title: 'Criar cartão' },
                { name: 'merge', title: 'Mesclar' },
                { name: 'markRead', title: 'Marcar como lida' },
                { name: 'closeRoom', title: 'Encerrar conversa' },
            ],
        }
    },
    mounted() {
        this.getRooms();
        let self = this;
        setTimeout(function () {
            self.loadingChat = false;
        }, 500);
    },
    computed: {
        ...mapState("user", ["userLogged"]),
        ...mapState("chat", ["rooms"]),
        ...mapGetters("user", ["hasRule", "hasPermission"]),
        ...mapGetters("chat", ["isRoomOpen", "usersFilter"]),
        ...mapGetters("generic", ["event", "showModal"]),
        messagesFilter() {
            const filter = this.messages.filter(x => x.conversationId === this.roomId);
            return filter;
        },
        isNeedTemplate() {
            var isNeed = this.messages.find(message => {
                return message.isNeedTemplate === true
            })
            if (isNeed || this.messages.length == 0) return true;
            return false;
        },
        hasRuleCrm() {
            var crm = this.hasPermission("CRM");
            if (crm) {
                return crm.includes("chat");
            }
            return false;
        },
        hasRuleFinance() {
            var crm = this.hasPermission("Finance");
            if (crm) {
                return crm.includes("chat");
            }
            return false;
        },
        hasRuleSupport() {
            var support = this.hasPermission("Support");
            if (support) {
                return support.includes("chat");
            }
            return false;
        },
    },
    methods: {
        ...mapMutations("chat", ["addRooms", "addRoom",
            "closeRoom",
            "cleanRooms",
            "updateLastMessage",
            "updateUserInRoom",
            "updateRooms",
            "markAsRead",
            "markAsNotRead",
            "cleanStatusRoom"]),
        ...mapMutations("generic", ["addEvent", "openModal", "hideModal", "removeLoading"]),
        ...mapActions("generic", ["postApi", "postFileApi", "getApi"]),
        getRooms() {
            let params = {
                url: this.urlGet,
                obj: {
                    id: this.userLogged.user.id,
                    any: this.channel,
                    ids: this.usersFilter
                }
            };
            this.getApi(params).then((response) => {
                this.addRooms(response.content.data);
                this.getTemplate();
                this.removeLoading(["panel"]);
            });
        },
        getLastMessage(conversationId) {
            let params = { url: this.urlLastMessage, obj: { id: conversationId } };
            this.getApi(params).then((response) => {
                this.updateLastMessage(response.content);
            });
        },
        getTemplate() {
            let params = { url: this.urlGetTemplate, obj: { any: this.channel } };
            this.getApi(params).then((response) => {
                this.templates = response.content.templates;
            });
        },
        fetchMessages({ options = {}, room }) {
            setTimeout(async () => {
                if (options.reset) {
                    this.loadingHeader = true;
                    this.baseParams.currentPage = 1;
                    this.roomId = room.roomId;
                    this.roomNameFull = room.roomNameFull;
                    this.senderPhone = room.senderPhone;
                    this.senderEmail = room.senderEmail;
                    this.contact = room.contact;
                    this.cards = room.cards;
                    this.isLead = room.isLead;
                    this.responsibleUser = room.user;
                    this.conversationEdit = room.conversationEdit;
                    this.markRead();
                    this.messages = [];
                    this.messages = await this.getMessages();
                } else {

                    if (this.totalRecords == this.messages.length) {
                        this.messagesLoaded = true
                    }
                    else if (this.totalRecords == 0) {
                        this.messagesLoaded = true
                    }
                    else {
                        this.baseParams.currentPage++;
                        const messages = await this.getMessages();
                        this.messages = [...messages, ...this.messages]
                    }
                }
            }, 100);
        },
        async getMessages() {
            let params = { url: this.urlGetMessage, obj: { any: this.roomId, ...this.baseParams } };
            const response = await this.getApi(params);
            this.totalRecords = response.content.totalRecords;

            let self = this;
            setTimeout(function () {
                self.loadingHeader = false;
            }, 300);

            return response.content.data;
        },
        async receivedOrNewMessage() {
            this.baseParams.currentPage = 1;
            this.messages = await this.getMessages();
        },
        openRoom() {
            this.openModal("openRoom");
        },
        confirmCloseRoom() {
            this.openModal("closeRoom");
        },
        handleCloseRoom() {
            let params = {
                url: this.urlClose, obj: {
                    id: this.roomId
                },
                notNotifyToast: true
            };
            this.postApi(params).then((response) => {
                if (response.success) {
                    this.roomId = "";
                    this.messages = [];

                    this.hideModal();
                }
                this.removeLoading(["btnCloseRoom"]);
            });

        },
        sendMessage(message) {
            if (this.isNeedTemplate) {
                var message = {
                    _id: 7890,
                    content: 'Sua sessão de mensagens acabou, é necessário enviar um template',
                    system: true,
                    isNeedTemplate: true
                };
                this.messages.push(message);
                this.removeLoading(["btnSend"]);
            } else {
                this.loadingSendMessage = true;
                if (message.files) {
                    var self = this;
                    message.files.forEach(file => {
                        var fileName = `${file.name}.${file.extension}`;
                        self.upload(file, fileName);
                    });
                }
                else {
                    this.addNewMessage(message, "");
                }
            }
        },
        addNewMessage(message, fileName, urlFile) {
            this.loadingSendMessage = true;
            let params = {
                url: this.urlCreateMessage,
                obj: {
                    channel: this.channel,
                    content: fileName ? "" : message.content,
                    conversationId: this.roomId,
                    senderPhone: this.senderPhone,
                    userId: this.userLogged.user.id,
                    userName: this.userLogged.user.userName,
                    photo: this.userLogged.user.photo,
                    fileName: fileName,
                    urlFile: urlFile,
                },
                notNotifyToast: true
            };
            this.postApi(params).then(() => {
                this.loadingSendMessage = false;
                this.removeLoading(["btnSend"]);
            });
        },
        sendTemplate(template) {
            this.loadingSendMessage = true;
            let params = {
                url: this.urlCreateMessageTemplate,
                obj: {
                    channel: this.channel,
                    templateId: template.id,
                    content: template.content,
                    params: template.params,
                    conversationId: this.roomId,
                    senderPhone: this.senderPhone,
                    userId: this.userLogged.user.id,
                    userName: this.userLogged.user.userName,
                    photo: this.userLogged.user.photo,
                },
                notNotifyToast: true
            };
            this.postApi(params).then(() => {
                this.loadingSendMessage = false;
                this.hideModal();
                this.removeLoading(["btnSendTemplate"]);
            });
        },
        markRead() {
            let params = {
                url: this.urlMarkAsRead,
                obj: {
                    id: this.roomId,
                },
                notNotifyToast: true
            };
            this.postApi(params).then(() => {
                this.markAsRead(this.roomId);
            });
        },
        upload(file, fileName, message) {
            let params = {
                url: this.urlFile,
                file: new File([file.blob], fileName),
                container: "nixloc-gupshup",
            };
            this.postFileApi(params).then((response) => {
                if (response.success) {
                    this.addNewMessage(message, fileName, response.content);
                }
            });
        },
        verifyIfGetRooms(roomId) {
            this.getRooms();

            let self = this;
            setTimeout(function () {
                self.markAsNotRead(roomId);
            }, 300);
        },
        openFile(param) {
            var urlFile = param.detail[0].file.file.url;
            let newUrl = urlFile.replace("download=false", "download=true");

            const downloadLink = document.createElement("a");
            downloadLink.href = newUrl;
            downloadLink.target = "_blank";
            downloadLink.click();
        },
        audio(eventData) {
            const message = require("../../../../../public/sound/message.mp3");
            var audio = new Audio(message);
            if (eventData.channel === this.channel) audio.play();
        },
        menuActionHandle(param) {
            var action = param.detail[0].action.name;
            if (action == "markRead") this.markRead();
            if (action == "closeRoom") this.confirmCloseRoom();
            if (action == "merge") this.openModal("merge");
            if (action == "createCard") this.openModal("card");
        },
        createJob() {

            const currentDate = new Date();

            const year = currentDate.getFullYear();
            const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
            const day = currentDate.getDate().toString().padStart(2, '0');

            const dateStart = `${day}/${month}/${year}`;

            this.openModal("job");
            this.addEvent({
                name: "jobCreateFromChat",
                data:
                {
                    dateStart: dateStart,
                    senderEmail: this.senderEmail,
                    contact: { id: this.contact.id, content: this.roomNameFull },
                }
            });
        },
        openTemplate() {
            this.openModal("template");
        },

    },
    watch: {
        channel: {
            handler() {
                this.getRooms();
            },
            deep: true,
        },
        event: {
            handler(event) {
                const eventName = event.name;
                const eventData = event.data;

                if (eventName == "conversationStarted") {
                    this.roomId = eventData.id;
                    this.getRooms();
                }

                if (eventName == "templateLeadQueueSent") {
                    this.getRooms();
                }

                if (eventName == "mergeDone") {
                    this.cleanRooms();
                    this.getRooms();
                    this.hideModal();
                }

                if (eventName == "createNewLead") {
                    this.audio(eventData);
                    this.getRooms();
                }

                if (eventName == "scheduleCreated") {
                    this.handleCloseRoom();
                    this.hideModal();
                }

                if (eventName == "updateStatusMessage") {
                    this.messages.forEach(msg => {
                        if (msg.id === eventData.id) {
                            msg.saved = eventData.saved;
                            msg.distributed = eventData.distributed;
                            msg.seen = eventData.seen;
                            msg.failure = eventData.failure;
                        }
                    });
                }

                if (eventName == "newMessageUserInternal") {
                    this.receivedOrNewMessage();
                    this.getLastMessage(eventData.conversationId);
                }

                if (eventName == "newMessageOrConversation") {
                    if (eventData.id == this.roomId) {
                        this.receivedOrNewMessage();
                    }
                    else {
                        this.verifyIfGetRooms(eventData.id);
                    }

                    if (this.roomId == "") {
                        this.getRooms();
                    }

                    this.audio(eventData);
                    this.getLastMessage(eventData.id);
                    this.markAsNotRead(eventData.id);
                }
            },
            deep: true,
        },
        responsibleUser: {
            handler(responsibleUser) {
                this.updateUserInRoom({ roomId: this.roomId, user: responsibleUser })
            },
            deep: true,
        },
        usersFilter: {
            handler() {
                let self = this;
                setTimeout(function () {
                    self.updateRooms([]);
                    self.getRooms();
                }, 100);
            },
            deep: true,
        },
    }
}
</script>

<style scoped>
.icon-whats {
    color: green;
    font-size: 18px;
}

.not-message {
    color: rgb(156, 154, 154);
    font-style: italic;
}

.warning {
    color: orange;
}

.div-open-template {
    cursor: pointer;
}

.div-btn-send {
    margin-bottom: 15px;
}

.div-card {
    margin-left: 15px;
    font-size: 16px;
}

.badge-chat {
    margin-left: 10px;
    padding-left: 5px;
    padding-right: 5px;
    display: inline-block;
    font-size: 13px;
    text-align: center;
    border-radius: 5px;
}

.customer {
    background-color: #E9F6F0;
    border: 1px solid #49CC90;
}

.lead {
    background-color: #ECF3FB;
    border: 1px solid #61AFFE;
}


.div-user {
    margin-left: 15px;
    margin-right: 50px;
}

.divider {
    font-size: 6px;
    color: white;
}

.div-schedule-job {
    font-size: 13px;
    margin-right: 10px;
    color: white;
    background-color: #3E8FD0;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 50px;
    cursor: pointer;
}

.link {
    color: #3f529b;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
}

.link:hover {
    text-decoration: underline;
}
</style>
<template>
    <div>
        <b-row>
            <b-col sm="5">
                <InputText title="Descrição" field="description" :required="false" :maxLength="50" v-model="description" />
            </b-col>
            <b-col sm="4">
                <DateTime title="Retorno" :required="false" format="DD/MM/YYYY HH:mm" type="dateTime" :range="false"
                    v-model="dateReturn" />
            </b-col>
            <b-col sm="2">
                <div class="div-btn-save">
                    <Button _key="btnSave" type="primary" :disabled="dateReturn == '' || description == ''"
                        classIcon="fa-solid fa-circle-plus" size="small" :clicked="save" />
                </div>
            </b-col>
        </b-row>
    </div>
</template>
<script>


import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import DateTime from "@nixweb/nixloc-ui/src/component/forms/DateTime";
import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import Select from "@nixweb/nixloc-ui/src/component/forms/Select";

import { mapMutations, mapActions } from "vuex";

export default {
    name: "ScheduleCreate",
    components: {
        Button, DateTime, InputText, Select
    },
    props: ["cardId",
        "customerSupplierId",
        "conversationId"],
    data() {
        return {
            urlSchedule: "/api/v1/chat/schedule/create",
            contactId: {},
            description: "",
            dateReturn: "",
        }
    },
    methods: {
        ...mapMutations("generic", ["addEvent", "openModal", "removeLoading"]),
        ...mapActions("generic", ["postApi"]),
        save() {
            let params = {
                url: this.urlSchedule,
                obj: {
                    conversationId: this.conversationId,
                    cardId: this.cardId,
                    description: this.description,
                    dateReturn: this.dateReturn,
                },
                notNotifyToast: true
            };
            this.postApi(params).then(() => {
                this.addEvent({ name: "scheduleCreated" });
                this.description = "";
                this.dateReturn = "";
                this.removeLoading(["btnSave"]);
            });
        },
    },

}
</script>
<style scoped>
.div-btn-save {
    margin-top: 35px;
}
</style>
<template>
    <div>
        <div v-if="loading">
            <Loading v-if="loading" type="line" />
            <span>Aguarde, estamos distribuindo os leads...</span>
        </div>
        <div class="div-btn-update">
            <Button _key="btnUpdate" title="Atualizar" classIcon="fa-solid fa-arrows-rotate" size="small" type="info"
                :clicked="getAll" />
        </div>
        <ScrollBar :minHeight="400" :maxHeight="400">
            <table class="table table-responsive-xs">
                <thead>
                    <tr>
                        <th>
                            <span class="title-header">Canal</span>
                        </th>
                        <th>
                            <span class="title-header">Source</span>
                        </th>
                        <th>
                            <span class="title-header">Tipo</span>
                        </th>
                        <th class="td-payload">
                            <span class="title-header">Dados</span>
                        </th>
                        <th class="text-center">
                            <i class="fa-solid fa-calendars"></i>
                            <span class="title-header">
                                Data
                            </span>
                        </th>
                    </tr>
                </thead>
                <tbody v-for="item in data">
                    <td>
                        {{ item.json.app }}
                    </td>
                    <td>
                        {{ item.json.payload.source }}
                        <span v-if="item.source">
                            55{{ item.source }}
                        </span>
                    </td>
                    <td>
                        {{ item.json.type }}
                    </td>
                    <td>
                        <span v-if="item.json.type == 'message-event'">
                            {{ item.json.payload.type }}
                        </span>
                        <span v-if="item.json.type == 'message'">
                            <span v-if="item.json.payload.type == 'text'">
                                {{ item.json.payload.payload.text }}
                            </span>
                            <span v-else>
                                {{ item.json.payload.type }}
                            </span>
                        </span>
                    </td>
                    <td class="text-center">
                        <span>
                            {{ item.dateStr }}
                        </span>
                    </td>
                </tbody>
                <tbody v-show="data.length == 0">
                    <tr>
                        <td colspan="12">
                            <span class="title">Nenhum registro encontrado!</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </ScrollBar>
    </div>
</template>
<script>

import ScrollBar from "@nixweb/nixloc-ui/src/component/layout/ScrollBar.vue";
import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";

import { mapActions, mapMutations, mapGetters } from "vuex";

export default {
    name: "ScheduleList",
    components: {
        Molded, ScrollBar, Button
    },
    props: ["channel"],
    data() {
        return {
            urlGetAll: "/api/v1/gupshup/get-all",
            data: [],
            baseParams: {
                search: undefined,
                filter: "contains",
                currentPage: 1,
                totalPerPage: 50,
            },
            loading: true
        }
    },
    computed: {
        ...mapGetters("generic", ["event"]),
    },
    mounted() {
        this.getAll();
    },
    methods: {
        ...mapActions("generic", ["getApi"]),
        ...mapMutations("generic", ["removeLoading"]),
        getAll() {
            let params = { url: this.urlGetAll, obj: { ...this.baseParams } };
            this.getApi(params).then((response) => {
                this.data = [];
                response.content.data.forEach(item => {
                    let obj = {
                        source: item.source,
                        json: JSON.parse(item.json),
                        dateStr: item.dateStr
                    }
                    this.data.push(obj);
                });
                this.loading = false;
                this.removeLoading(["btnUpdate"]);
            });
        },
    },
    watch: {
        event: {
            handler(event) {
                if (event.name == "getLogGupShup") {
                    this.getAll();
                }
            },
            deep: true,
        },
    },
}
</script>
<style scoped>
.table th,
.table td {
    height: 10px !important;
    padding-left: 5px !important;
    padding-top: 7px !important;
    padding-bottom: 5px !important;
    padding-right: 5px !important;
    padding-left: 10px !important;
    border-bottom: 0px !important;
}


.title-header {
    font-size: 14px;
    color: #757d8c;
    font-weight: 400;
    text-transform: uppercase;
}

.td-payload {
    width: 200px;
}

.div-btn-update {
    margin-bottom: 15px;
}
</style>
export default class Conversation {
    constructor() {
        this.id = "";
        this.name = "";
        this.channel = 0;
        this.companyName = "";
        this.categoryCustomer = { id: "", content: "" };
        this.phone = "";
        this.email = "";
        this.userId = "";
    }
    update(data) {
        this.id = data.id;
        this.name = data.name;
        this.companyName = data.companyName;
        this.categoryCustomer = data.categoryCustomer;
        this.phone = data.phone;
        this.email = data.email;
    }
}
<template>
  <div>
    <b-row>
      <b-col sm="6">
        <InputText title="Razão Social/Nome" field="name" :formName="formName" :required="true" :maxLength="100"
          v-model="conversationEdit.customerSupplier.person.companyName" :markFormDirty="false" />
      </b-col>
      <b-col sm="6">
        <InputText title="Fantasia/Apelido" field="name" :formName="formName" :required="true" :maxLength="100"
          v-model="conversationEdit.customerSupplier.person.tradeName" :markFormDirty="false" />
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="6">
        <Select title="Categoria" field="category" :formName="formName" :required="true" :markFormDirty="false"
          :showNewRegister="false" url="/api/v1/crm/category-customer/select-all"
          v-model="conversationEdit.categoryCustomer">
        </Select>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="6">
        <InputText title="Nome" field="name" :formName="formName" :required="true" :maxLength="50"
          v-model="conversationEdit.contact.name" :markFormDirty="false" />
      </b-col>
      <b-col sm="4">
        <InputText title="Celular" :required="true" field="mobile" :mask="['(##) ####-####', '(##) #####-####']"
          :formName="formName" :maxLength="20" :markFormDirty="markFormDirty" v-model="conversationEdit.contact.mobile" />
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12">
        <InputText title="E-mail" field="name" :formName="formName" :required="false" :maxLength="100"
          v-model="conversationEdit.contact.email" :markFormDirty="false" />
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12">
        <div class="text-center">
          <Button _key="saveConversation" type="success" title="Salvar" :disabled="!isFormValid(formName)"
            classIcon="fa-solid fa-floppy-disk" size="medium" :clicked="saveConversation" />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Select from "@nixweb/nixloc-ui/src/component/forms/Select";

import { mapGetters, mapState, mapActions, mapMutations } from "vuex";

export default {
  name: "ConversationEdit",
  props: {
    conversationEdit: Object
  },
  components: { Button, InputText, Select },
  data() {
    return {
      formName: "conversationEdit",
      urlEdit: "/api/v1/chat/conversation/edit-conversation",
    };
  },
  computed: {
    ...mapState("user", ["userLogged"]),
    ...mapGetters("validation", ["isFormValid"]),
    ...mapGetters("generic", ["event"]),
  },
  methods: {
    ...mapActions("generic", ["putApi"]),
    ...mapMutations("generic", ["addEvent", "removeLoading", "hideModal"]),
    ...mapMutations("validation", ["resetValidation"]),
    saveConversation() {
      let params = { url: this.urlEdit, obj: this.conversationEdit };
      this.putApi(params).then((response) => {
        if (response.success) {
          this.addEvent({ name: "conversationEdited" });
          this.hideModal();
        }
        this.removeLoading(["saveConversation"]);
      });
    }
  },
};
</script>

export default class Card {
    constructor() {
        this.id = "";
        this.customerSupplier = { id: "", content: "" };
        this.frame = { id: "", content: "" };
        this.step = { id: "", content: "" };
        this.comment = "";
        this.stepId = "";
        this.userId = "";
    }
}
<template>
    <div>
        <b-row>
            <b-col sm="12">
                <Select title="Contato" field="contact" :formName="formName" :required="true" :markFormDirty="false"
                    :showNewRegister="false" url="/api/v1/shared/contact/select-all-without-customer" v-model="contact">
                </Select>
            </b-col>
        </b-row>
        <div class="text-center">
            <Button _key="create" type="success" title="Iniciar" :disabled="!isFormValid(formName)"
                classIcon="fa-solid fa-circle-plus" size="medium" :clicked="open" />
        </div>
    </div>
</template>

<script>

import Select from "@nixweb/nixloc-ui/src/component/forms/Select";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";

import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
    name: "ConversationCreate",
    components: {
        Select, Button
    },
    props: {
        channel: Number
    },
    data() {
        return {
            formName: "conversationCreate",
            contact: {},
            urlOpen: "/api/v1/chat/conversation/open",
        };
    },
    computed: {
        ...mapGetters("validation", ["isFormValid"]),
    },
    methods: {
        ...mapActions("generic", ["postApi"]),
        ...mapMutations("generic", ["addEvent", "removeLoading", "hideModal"]),
        open() {
            let params = {
                url: this.urlOpen, obj: {
                    channel: this.channel,
                    contactId: this.contact.id,
                },
                notNotifyToast: true
            };
            this.postApi(params).then((response) => {
                if (response.success) {
                    this.addEvent({ name: "conversationStarted", data: response.content });
                    this.hideModal();
                }
                this.removeLoading(["create"]);
            });
        }
    }
}
</script>
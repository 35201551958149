<template>
  <div>
    <b-row>
      <b-col sm="12">
        <Select title="Quadro" field="frame" :formName="formName" :required="true" :markFormDirty="false"
          :showNewRegister="false" url="/api/v1/planner/frame/select-all" v-model="card.frame">
        </Select>
      </b-col>
      <b-col sm="12">
        <Select title="Etapa" field="step" :propsParams="{ any: card.frame.id }" :formName="formName" :required="true"
          :markFormDirty="false" :showNewRegister="false" url="/api/v1/planner/step/select-all" v-model="card.step">
        </Select>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12">
        <InputText title="Nota" field="name" :formName="formName" :required="false" :maxLength="2000"
          v-model="card.comment" :markFormDirty="false" />
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12">
        <div class="text-center">
          <Button _key="saveCard" type="success" title="Criar" :disabled="!isFormValid(formName)"
            classIcon="fa-solid fa-circle-plus" size="medium" :clicked="saveCard" />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Select from "@nixweb/nixloc-ui/src/component/forms/Select";

import CardFromChat from "@/components/modules/planner/card/CardFromChat.js";

import { mapGetters, mapState, mapActions, mapMutations } from "vuex";

export default {
  name: "CardCreate",
  props: {
    customerSupplierId: Object,
  },
  components: { Button, InputText, Select },
  data() {
    return {
      frameId: this.$route.params.id,
      formName: "cardFromChat",
      card: new CardFromChat(),
      urlCreate: "/api/v1/planner/card/create",
    };
  },
  computed: {
    ...mapState("user", ["userLogged"]),
    ...mapGetters("validation", ["isFormValid"]),
    ...mapGetters("generic", ["event"]),
  },
  methods: {
    ...mapActions("generic", ["postApi"]),
    ...mapMutations("generic", ["addEvent", "removeLoading", "hideModal"]),
    ...mapMutations("validation", ["resetValidation"]),
    saveCard() {
      this.card.customerSupplier.id = this.customerSupplierId;
      this.card.frameId = this.card.frame.id;
      this.card.stepId = this.card.step.id;
      this.card.userId = this.userLogged.user.id;
      let params = { url: this.urlCreate, obj: this.card };
      this.postApi(params).then((response) => {
        if (response.success) {
          this.addEvent({ name: "cardCreated" });
          this.hideModal();
        }
        this.removeLoading(["saveCard"]);
      });
    }
  },
  watch: {
    'card.frame': {
      handler() {
        this.card.step = {};
      },
      deep: true,
    },
  }
};
</script>

<template>
    <div class="div-main">
        <div v-if="loading">
            <Loading v-if="loading" type="line" />
            <span>Aguarde, estamos distribuindo os leads...</span>
        </div>
        <ScrollBar v-else :minHeight="400" :maxHeight="400">
            <div class="div-lead" v-for="lead in leadQueue">
                <Molded>
                    <b-row>
                        <b-col sm="1">
                            <div class="div-person">
                                <i class="fa-solid fa-user"></i>
                            </div>
                        </b-col>
                        <b-col sm="9">
                            <div class="side-by-side div-title">
                                {{ lead.companyName }}
                            </div>
                            <div class="side-by-side div-title">
                                ({{ lead.name }})
                            </div>
                            <div class="side-by-side div-title">
                                -
                                <i class="fa-brands fa-whatsapp"></i>
                                {{ lead.whatsApp }}
                            </div>
                            <div class="side-by-side div-title">
                                <span class="title-date">
                                    <i class="fa-light fa-calendar"></i>
                                    {{ lead.registeredDateStr }}
                                </span>
                            </div>
                        </b-col>
                        <b-col sm="1">
                            <img class="img-current" :src="baseUrl + lead.user.photo" alt="">
                        </b-col>
                        <b-col sm="1">
                            <div class="div-send">
                                <Button :_key="lead.id" classIcon="fa-solid fa-paper-plane-top" type="primary" size="small"
                                    :params="lead" :clicked="sendTemplate" />
                            </div>
                        </b-col>
                    </b-row>
                </Molded>
            </div>
            <div v-show="leadQueue.length == 0">
                <span class="title">Nenhum registro encontrado!</span>
            </div>
        </ScrollBar>
    </div>
</template>
<script>

import ScrollBar from "@nixweb/nixloc-ui/src/component/layout/ScrollBar.vue";
import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";

import { mapActions, mapMutations, mapState, mapGetters } from "vuex";

export default {
    name: "LeadQueueList",
    components: {
        Molded, ScrollBar, Button, Loading
    },
    data() {
        return {
            baseUrl: "https://espaco.blob.core.windows.net/nixloc-photo-user/",
            urlTemplate: "/api/v1/crm/lead-queue/send-template",
            urlGetAll: "/api/v1/crm/lead-queue/get-all",
            loading: true
        }
    },
    computed: {
        ...mapState("chat", ["leadQueue"]),
        ...mapGetters("chat", ["usersFilter"]),
        ...mapGetters("generic", ["event"]),
    },
    mounted() {
        this.getAll();
    },
    methods: {
        ...mapActions("generic", ["getApi", "postApi"]),
        ...mapMutations("chat", ["addLeadQueue"]),
        ...mapMutations("generic", ["addEvent", "removeLoading"]),
        sendTemplate(lead) {
            let params = {
                url: this.urlTemplate, obj: {
                    id: lead.id,
                    name: lead.user.name,
                    userId: lead.user.id,
                }
            };
            this.postApi(params).then((response) => {
                if (response.success) {
                    this.getAll();
                    this.addEvent({ name: "templateLeadQueueSent" });
                }
                this.removeLoading([lead.id]);
            });
        },
        getAll() {
            this.loading = true;
            let params = { url: this.urlGetAll, obj: { ids: this.usersFilter } };
            this.getApi(params).then((response) => {
                this.addLeadQueue(response.content.data);
                this.loading = false;
            });
        },
    },
    watch: {
        event: {
            handler(event) {
                if (event.name === "getLeadQueue") {
                    this.getAll();
                }
            },
            deep: true,
        },
    },
}

</script>
<style scoped>
.div-main {
    height: 400px;
}


.div-lead {
    margin-bottom: 10px;
    margin-right: 10px;
}

.div-person {
    font-size: 20px;
}

.div-title {
    margin-right: 10px;
    margin-top: 3px;
}

.div-send {
    font-size: 18px;
    color: darkblue;
    cursor: pointer;
}

.div-send:hover {
    color: rgb(123, 123, 184);
}

.img-current {
    width: 30px;
    height: 30px;
    border-radius: 50px;
    margin-top: 3px;
}

.fa-whatsapp {
    color: green;
}

.title-date {
    color: gray;
    font-style: italic;
    font-size: 13px;
}
</style>
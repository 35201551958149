<template>
  <div>
    <b-row>
      <b-col sm="6">
        <InputText title="Empresa" field="name" :formName="formName" :required="true" :maxLength="100"
          v-model="newConversation.companyName" :markFormDirty="false" />
      </b-col>
      <b-col sm="5">
        <Select title="Categoria" field="category" :formName="formName" :required="true" :markFormDirty="false"
          :showNewRegister="false" url="/api/v1/crm/category-customer/select-all"
          v-model="newConversation.categoryCustomer">
        </Select>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="6">
        <InputText title="Nome" field="name" :formName="formName" :required="true" :maxLength="50"
          v-model="newConversation.name" :markFormDirty="false" />
      </b-col>
      <b-col sm="4">
        <InputText title="Celular" :required="true" field="mobile" :mask="['(##) ####-####', '(##) #####-####']"
          :formName="formName" :maxLength="20" :markFormDirty="markFormDirty" v-model="newConversation.mobile" />
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12">
        <InputText title="E-mail" field="name" :formName="formName" :required="false" :maxLength="100"
          v-model="newConversation.email" :markFormDirty="false" />
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12">
        <div class="text-center">
          <Button _key="saveNewConversation" type="success" title="Iniciar" :disabled="!isFormValid(formName)"
            classIcon="fa-solid fa-circle-plus" size="medium" :clicked="saveNewConversation" />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Select from "@nixweb/nixloc-ui/src/component/forms/Select";

import NewConversation from "@/components/modules/chat/conversation/NewConversation.js";

import { mapGetters, mapState, mapActions, mapMutations } from "vuex";

export default {
  name: "NewConversationCreate",
  props: {
    channel: Number
  },
  components: { Button, InputText, Select },
  data() {
    return {
      formName: "NewConversationCreate",
      newConversation: new NewConversation(),
      urlCreate: "/api/v1/chat/conversation/new-conversation",
    };
  },
  computed: {
    ...mapState("user", ["userLogged"]),
    ...mapGetters("validation", ["isFormValid"]),
    ...mapGetters("generic", ["event"]),
  },
  methods: {
    ...mapActions("generic", ["postApi"]),
    ...mapMutations("generic", ["addEvent", "removeLoading", "hideModal"]),
    ...mapMutations("validation", ["resetValidation"]),
    saveNewConversation() {
      this.newConversation.channel = this.channel;
      this.newConversation.userId = this.userLogged.user.id;
      let params = { url: this.urlCreate, obj: this.newConversation };
      this.postApi(params).then((response) => {
        if (response.success) {
          this.addEvent({ name: "conversationStarted", data: response.content });
          this.hideModal();
        }
        this.removeLoading(["saveNewConversation"]);
      });
    }
  },
};
</script>

<template>
    <div>
        <Select title="Empresa" field="company" :required="true" :markFormDirty="false" :showNewRegister="false"
            url="/api/v1/crm/customer/select-all" v-model="customer" />
        <div class="text-center">
            <Button _key="btnMerge" type="primary" title="Mesclar" classIcon="fa-solid fa-merge" size="small"
                :clicked="merge" />
        </div>
    </div>
</template>

<script>

import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Select from "@nixweb/nixloc-ui/src/component/forms/Select";

import { mapMutations, mapActions } from "vuex";

export default {
    name: "Merge",
    props: ["contactId"],
    components: {
        Button, Select
    },
    data() {
        return {
            urlMerge: "/api/v1/chat/conversation/merge",
            customer: {}
        }
    },
    methods: {
        ...mapMutations("generic", ["addEvent", "removeLoading"]),
        ...mapActions("generic", ["postApi"]),
        merge() {
            let params = {
                url: this.urlMerge,
                obj: {
                    id: this.contactId,
                    any: this.customer.id,
                },
                notNotifyToast: true
            };
            this.postApi(params).then(() => {
                this.addEvent({ name: "mergeDone" });
                this.removeLoading(["btnMerge"]);
            });
        },
    }
}

</script>